.home-page {
    z-index: 1;

    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }
    h1 {
        color: #fff;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #8EF4FF;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            // animation: fadeIn 0s 1s backwards;
            opacity: 1;
        }

        &::after {
            content: '</h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #8EF4FF;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 3.6s backwards;
            opacity: 1;
        }

        img {
            width: 88px;
            // margin-left: px;
            margin-right: -15px;
            margin-bottom: -22px;
            opacity: 0;
            height: auto;
            animation: rotateIn 0.7s linear both;
            animation-delay: 1.4s;
            white-space: nowrap;
        }
    }

    h2 {
        color: #a4a4a4;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 3.7s backwards;
    }

    .flat-button {
        color: #fcfcfc;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid hsl(0, 0%, 100%);
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 3.7s backwards;
        white-space: nowrap;


        &:hover {
            background: #8EF4FF;
            color: #333;

        }

    }
}

@media screen and (max-width: 1200px) {
    .tags,
    .home-page h1:before,
    .home-page h1:after {
      display: none;
    }
  
    .home-page .text-zone {
      position: initial;
      width: 100%;
      transform: none;
      padding: 10px;
      box-sizing: border-box;
      text-align: center;
    }
  
    .home-page .flat-button {
      float: none;
      display: block;
      margin: 20px auto 0 auto;
      width: 124px;
    }
  
    .logo-container {
      position: relative;
      width: 80px;
      height: auto;
      top: 50px;
      right: -15px;
      box-sizing: border-box;
      margin: auto;
      left: auto;
    }
  
    .logo-container svg {
      width: 254%;
      position: absolute;
      top: -27px;
      right: auto;
      bottom: auto;
      left: -80.5px;
      margin: auto;
    }
}