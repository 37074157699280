.logo-container{
    z-index: -1;
    width: 500px;
    height: 550px;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
    opacity: 1;

    svg {
        width: 100%;
        height: auto;
        bottom: 0;
        // transform: rotateZ(30deg) !important;
    }

    .solid-logo {
        position: absolute;
        top: -30px;
        right: 0px;
        bottom: 0px;
        left: -121px;
        // margin: auto;
        width: 157%;
        opacity: 1;
        // translate: -150px -100px;
        // transform: rotateZ(30deg);
        animation: fadeIn 1s 5s backwards;
        z-index: -1;
    }
}

.svg-container {
    translate: 0 -88px;
    stroke: #f0f0f0;
    stroke-width: 3;
}

// .svg-ani1 {
//     stroke-dasharray: 50;
//     stroke-dashoffset: 50;
//     animation: dash 5s forwards;
// }

// .svg-ani2 {
//     stroke-dasharray: 50;
//     stroke-dashoffset: 50;
//     animation: dash 5s forwards;
// }

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

@media screen and (max-width: 1200px) {
    .logo-container{
        translate: 0px 0px;
        .solid-logo {
            // position: absolute;
            top: -40px;
            left: -130px;
            width: 400%;
            
            // margin: auto; 
        }
        svg{
            position: absolute;
            width: 200%;
            left: -70px;
        }
    }
    
}